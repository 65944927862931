//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gql from 'graphql-tag'
const customerCreateGql = gql`mutation customerCreate($input: CustomerCreateInput!){
	customerCreate(input:$input) {
    customer {
      email
    }
    customerUserErrors{
      code
      field
      message
    }
  }
}`
const customerAccessTokenCreateGql = gql`mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!){
	customerAccessTokenCreate(input:$input) {
    customerAccessToken{
      accessToken
      expiresAt
    }
    customerUserErrors{
      code
      field
      message
    }
  }
}`
const customerRecoverGql = gql`mutation customerRecover($email: String!){
	customerRecover(email:$email) {
    customerUserErrors{
      code
      field
      message
    }
  }
}`
const customerGql = gql`query customer($customerAccessToken:String!){
  customer(customerAccessToken:$customerAccessToken){
    firstName
    email
    orders(reverse:true,first:250){
      edges{
        node{
          fulfillmentStatus
          lineItems(first:1){
            edges{
              node{
                customAttributes{
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}` 
export default {
  props:{
    visible: {
    type: Boolean,
    required: true
    }
  },
  data() {
    return {
      formType:1,
      login:{
        email:null,
        password:null,
        loading:false,
      },
      register:{
        email:null,
        password:null,
        firstName:null,
        lastName:null,
        loading:false
      },
      forgot:{
        email:null,
        loading:false,
        noticeShow:false
      },
      customer:null,
    }
  },
  methods:{
    getUserInfo(token){
      this.$apollo.addSmartQuery('customer', {
        query: customerGql, variables: {
          customerAccessToken: token,
        }, result({ data, loading, networkStatus}) {
            this.login.loading = false
            this.$store.commit('Email', data.customer.email)
            this.$store.commit('Name', data.customer.firstName)
            if(data.customer.orders.edges.length>0){
              let UserDevices = []
              data.customer.orders.edges.forEach(item => {
                if (item.node.fulfillmentStatus === "FULFILLED"&&item.node.lineItems.edges[0].node.customAttributes.length>0) {
                  UserDevices.push(item.node.lineItems.edges[0].node.customAttributes[0].value)
                }
              });
              this.$store.commit('UserDevices', [...new Set(UserDevices)])
            }
            if(this.$cookie.get('TSimAccessToken')&&this.$store.state.name&&this.$store.state.email){
              this.$store.commit('Login', true)
              this.handleCancel()
            }
        },// 错误处理
        error(error) {
            this.$message.error(error.message)
        }
      });
    },
    onForgot(){
      this.forgot.noticeShow = false
      this.forgot.loading = true
      this.$apollo
        .mutate({
            // 更新的语句
            mutation: customerRecoverGql,
            // 实参列表
            variables: {
              "email": this.forgot.email
            }
        }).then(response => {
            this.forgot.loading = false
            if(response.data.customerRecover.customerUserErrors.length>0){
              response.data.customerRecover.customerUserErrors.forEach(item => {
                this.$message.error(item.message)
              });
            }else{
              this.forgot.noticeShow = true
            }
        }).catch(error => {
            this.forgot.loading = false
            this.$message.error(error.message)
        });
    },
    onLogin(){
      this.login.loading  = true
       this.$apollo
        .mutate({
            // 更新的语句
            mutation: customerAccessTokenCreateGql,
            // 实参列表
            variables: {
              "input": {
                "email": this.login.email,
                "password": this.login.password,
              }
            }
        }).then(response => {
            if(response.data.customerAccessTokenCreate.customerAccessToken.accessToken){
              const token = response.data.customerAccessTokenCreate.customerAccessToken.accessToken
              const expiresAt = new Date(response.data.customerAccessTokenCreate.customerAccessToken.expiresAt)
              this.$cookie.set('TSimAccessToken', token, { expires: expiresAt });
              this.getUserInfo(token)
            }
            if(response.data.customerAccessTokenCreate.customerUserErrors){
              response.data.customerAccessTokenCreate.customerUserErrors.forEach(item => {
                this.$message.error(item.message)
              });
            }
        }).catch(error => {
            this.login.loading  = false
            this.$message.error(error.message)
        })
    },
    onRegister(){
      this.register.loading = true
      this.$apollo
        .mutate({
            // 更新的语句
            mutation: customerCreateGql,
            // 实参列表
            variables: {
              "input": {
                "email": this.register.email,
                "firstName": this.register.firstName,
                "lastName": this.register.lastName,
                "password": this.register.password,
              }
            }
        }).then(response => {
            this.register.loading = false
            if(response.data.customerCreate.customer){
              this.$message.success(this.$t('common.welcomeTo'))
              this.resetValidation(1)
            }
            if(response.data.customerCreate.customerUserErrors){
              response.data.customerCreate.customerUserErrors.forEach(item => {
                this.$message.error(item.message)
              });
            }
        }).catch(error => {
            this.register.loading = false
            this.$message.error(error.message)
        });
    },
    handleCancel () {
      this.resetValidation(1)
      this.$emit('cancel')
    },
    resetValidation(type){
      switch (this.formType) {
        case 2:
          this.register = this.$options.data().register
          this.$refs.register.resetValidation();
          break 
        case 3:
          this.forgot = this.$options.data().forgot
          this.$refs.forgot.resetValidation();
          break 
        default:
          this.login = this.$options.data().login
          this.$refs.login.resetValidation()
          break 
      }
      this.formType = type
    }
  }
}
