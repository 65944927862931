import axios from 'axios'
import CryptoJS from 'crypto-js/crypto-js'
import { VueAxios } from './axios'

// import { Message} from 'ant-design-vue';
// Message.warning(i18n.t('common.loginTips'))

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_RESTFUL_API_HOST,
    timeout: 60000 // 请求超时时间
})


// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const ACCOUNT = GETACCOUNT(config.url)
    const SECRET =  GETSECRET (config.url)
    const NONCE = GETNONCESTR()
    const TIMESTAMP = Date.parse( new Date() ).toString().substring(0,10)
    config.headers['TSIM-ACCOUNT'] = ACCOUNT
    config.headers['TSIM-NONCE'] = NONCE
    config.headers['TSIM-TIMESTAMP'] = TIMESTAMP
    config.headers['TSIM-SIGN'] = GETSIGN(ACCOUNT,NONCE,TIMESTAMP,SECRET)
    return config
}, errorHandler)


// response interceptor
request.interceptors.response.use((response) => {
    if (response.data.code !== 200) {
    }
    return response.data
}, errorHandler)

function GETACCOUNT (URL){
    if (URL === '/shopify/getDataplanList') {
        return process.env.VUE_APP_SEARCHLINK_ACCOUNT
    }else{
        return process.env.VUE_APP_ACCOUNT
    }
}

function GETSECRET (URL){
    if (URL === '/shopify/getDataplanList') {
        return process.env.VUE_APP_SEARCHLINK_SECRET
    }else{
        return process.env.VUE_APP_SECRET
    }
}

function GETNONCESTR() {
	// 随机字符串，6-32位
	let chars = 'ABCDEFGHJKMNOPQRSTUVWXYZabcdefhijkmnopqrstuvwxyz0123456789'
	let len = chars.length
	let num = Math.floor(Math.random() * 24 + 6)
	let str = ''
	for (let i = 0; i < num; i++) {
		str += chars.charAt(Math.floor(Math.random() * len))
	}
	return str
}
function GETSIGN(ACCOUNT,NONCE,TIMESTAMP,SECRET) {
    const str = ACCOUNT+NONCE+TIMESTAMP
	return CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(str, SECRET));
}

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}