//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gql from 'graphql-tag'
import GlobalVideo from '../components/GlobalVideo.vue'
const TabsGql = gql`query Tabs($first: Int!,$query: String!){
Tabs:collections(first:$first,query:$query){
  edges{
    node{
      handle
      title
    }
  }
}
}`;
const ProductsGql =  gql`query Products($handle: String!,$first: Int,$after: String,$last:Int,$before:String){
  Products:collection(handle:$handle){
    products(first:$first,after:$after,last:$last,before:$before){
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
      edges{
        cursor
        node{
          title
          handle
          id
          priceRange{
            minVariantPrice{
              amount
              currencyCode
            }
          }
          images(first:1){
            edges{
              node{
                url
              }
            }
          }
        }
      }
    }
  }
}`;
 export default {
  components:{
    GlobalVideo
  },
  data() {
    return {
      Tabs:[],
      Products:[],
      spinning:false,
      adShow:true,
      pageInfo:{
        handle:null,
        startCursor:null,
        endCursor:null,
        hasNextPage:true,
        hasPreviousPage:false,
      }
    }
  },
  created(){
    this.getTabs()
  },
  watch:{
    '$i18n.locale'(){
      this.getTabs()
    }
  },
  filters: {
    areaFilter(title,lang){
      if (lang === 'en') {
        return title.substring(3);
      }else{
        return title.substring(4);
      }
    }
  },
  methods: {
    getProducts({...params}){
      this.spinning = true
      this.$apollo.addSmartQuery('Products', {
          query: ProductsGql, variables: params
          , result({ data, loading, networkStatus}) {
              // console.log({ data, loading, networkStatus })
              this.spinning = false
              this.Products = [...data.Products.products.edges].sort((a,b)=> a.node.priceRange.minVariantPrice.amount - b.node.priceRange.minVariantPrice.amount)
              this.pageInfo.hasNextPage = data.Products.products.pageInfo.hasNextPage
              this.pageInfo.hasPreviousPage = data.Products.products.pageInfo.hasPreviousPage
              if(data.Products.products.edges.length>0){
                this.pageInfo.startCursor = data.Products.products.edges[0].cursor
                this.pageInfo.endCursor = data.Products.products.edges[data.Products.products.edges.length-1].cursor
              }
          },// 错误处理
          error(error) {
              this.spinning = false
              this.$message.error(error.message)
          }
      });
    },
    previous(){
      if(this.pageInfo.hasPreviousPage) this.getProducts({handle:this.pageInfo.handle,last: 8,before:this.pageInfo.startCursor})
    },
    next(){
      if(this.pageInfo.hasNextPage) this.getProducts({handle:this.pageInfo.handle,first: 8,after:this.pageInfo.endCursor})
    },
    getTabs(){
      this.$apollo.addSmartQuery('Tabs', {
          query: TabsGql, variables: {
            first: 250,
            query:`${this.$i18n.locale}_*`,
          }, result({ data, loading, networkStatus}) {
              // console.log({ data, loading, networkStatus })
              // this.Tabs = data.Tabs.edges.filter(item => item.node.title.includes(`${this.$i18n.locale}_`))
              this.Tabs = [...data.Tabs.edges].sort((a,b)=>  parseInt(a.node.handle) - parseInt(b.node.handle))
              this.pageInfo.handle = this.Tabs[0].node.handle
              this.getProducts({handle:this.pageInfo.handle,first:8})
          },// 错误处理
          error(error) {
              this.$message.error(error.message)
          }
      });
    }
  }
}
