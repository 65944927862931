import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginShow:false,
    login:false,
    name:null,
    email:null,
    UserDevices:[]
  },
  getters: {
    isShowLogin(state) {
      return state.loginShow
    }
  },
  mutations: {
    LoginShow(state,show){
      state.loginShow = show
    },
    Login(state,type){
      state.login = type
    },
    Name(state,value){
      state.name = value
    },
    Email(state,value){
      state.email = value
    },
    UserDevices(state,value){
      state.UserDevices = value
    },
  },
  actions: {
  },
  modules: {
  }
})
