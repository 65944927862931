import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title_en: 'Top-up',
      title_jpn: 'TOP',
      show:true
    },
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      login: true,
      title_en: 'Top-up Orders',
      title_jpn: '追加プラン',
      show:true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue')
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      title_en: 'Product details',
      title_jpn: '商品の詳細',
      show:true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue')
  },
  {
    path: '/enquiry',
    name: 'Enquiry',
    meta: {
      title_en: 'Dataplan enquiry',
      title_jpn: 'データプランのお问い合わせ',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Enquiry.vue')
  },
  {
    path: '/tsim',
    name: 'Tsim',
    meta: {
      title_en: 'TSim Tech',
      title_jpn: 'TSim Tech',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Tsim.vue')
  },
  {
    path: '/newplan',
    name: 'newPlan',
    meta: {
      title_en: 'New plans',
      title_jpn: '新プラン',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/newPlan/newPlan.vue')
  },
  {
    path: '/setting_tutorial/index',
    name: 'SettingStep',
    meta: {
      title_en: 'SettingStep',
      title_jpn: 'SettingStep',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingTutorial/index.vue')
  },
  {
    path: '/setting_tutorial/cn',
    name: 'SettingTutorialCN',
    meta: {
      title_en: '操作说明',
      title_jpn: '操作说明',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingTutorial/cn.vue')
  },
  {
    path: '/setting_tutorial/en',
    name: 'SettingTutorialEN',
    meta: {
      title_en: 'Instructions Guide',
      title_jpn: 'Instructions Guide',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingTutorial/en.vue')
  },
  {
    path: '/setting_tutorial/jp',
    name: 'SettingTutorialJP',
    meta: {
      title_en: 'SIMカードの操作手順',
      title_jpn: 'SIMカードの操作手順',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingTutorial/jp.vue')
  },
  {
    path: '/setting_tutorial/kr',
    name: 'SettingTutorialKR',
    meta: {
      title_en: '유심카드 설정방법',
      title_jpn: '유심카드 설정방법',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingTutorial/kr.vue')
  },
  {
    path: '/network_tutorial/video_tutorial',
    name: 'videoTutorial',
    meta: {
      title_en: 'How to connect to the Internet?',
      title_jpn: '設定方法',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NetworkTutorial/videoTutorial.vue')
  },
  {
    path: '/network_tutorial/image_tutorial',
    name: 'imageTutorial',
    meta: {
      title_en: 'How to fix slow network speed',
      title_jpn: 'インターネット速度低下を解決する方法',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/NetworkTutorial/imageTutorial.vue')
  },
  {
    path: '/esimplus/Terms',
    name: 'esimPlusTerms',
    meta: {
      title_en: 'Terms of Service',
      title_jpn: 'Terms of Service',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/esimplus/Terms.vue')
  },
  {
    path: '/esimplus/Policy',
    name: 'esimPlusPolicy',
    meta: {
      title_en: 'Privacy Policy',
      title_jpn: 'Privacy Policy',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/esimplus/Policy.vue')
  },
  {
    path: '/esimplus/Guide',
    name: 'esimPlusGuide',
    meta: {
      title_en: 'Instructions Guide',
      title_jpn: 'Instructions Guide',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/esimplus/Guide.vue')
  },
  {
    path: '/dataplan/index',
    name: 'DataPlanList',
    meta: {
      title_en: '套餐查询',
      title_jpn: '套餐查询',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPlan/index.vue')
  },
  {
    path: '/dataplan/search',
    name: 'DataPlanSearch',
    meta: {
      title_en: 'Dataplan Enquiry',
      title_jpn: 'Dataplan Enquiry',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPlan/search.vue')
  },
  {
    path: '/dataplan/order',
    name: 'DataPlanSearch',
    meta: {
      title_en: 'Dataplan Enquiry',
      title_jpn: 'Dataplan Enquiry',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPlan/order.vue')
  },
  {
    path: '/dataplan/group/:group',
    name: 'DataPlanGroup',
    meta: {
      title_en: 'Dataplan Enquiry',
      title_jpn: 'Dataplan Enquiry',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPlan/group.vue')
  },
  {
    path: '/dataplan/qrcode/:id',
    name: 'DataPlanQrcode',
    meta: {
      title_en: 'eSIM Info',
      title_jpn: 'eSIM Info',
      show:false
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPlan/qrcode.vue')
  },
  {
    path: '/apology',
    name: 'Apology',
    meta: {
      title_en: 'Apology',
      title_jpn: 'Apology',
      show:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/apology.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/** 解决跳转重复路由报错问题 */ 
const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}
export default router
