//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods:{
    open(links){
      if(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)=="micromessenger"){
        window.location.href = links
      }else{
        window.open(links,"_blank");
      }
    },
    goNewPlan(){
      this.$router.push({name:'newPlan'})
    }
  },
  
}
